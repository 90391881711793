input[type="file"] {
    position: relative;
    width: 26px;
}

input[type="file"]::file-selector-button {
    width: auto;
  color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
   
    
}

input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 50%px;
    left: 50%px;
    height: 24px;
    width: 24px;
    content: ""; 
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");

}


